<template>
  <div class="home framer">
    <img class="logo" src="../assets/logo.svg">
    <router-link class="back" :to="{name:'Switcher'}"><img src="../assets/back.svg" /></router-link>
    <h1>Nicht gefunden</h1>
    <p>
      Diese Seite gibt es nicht.
      <br />
      <br />
      <router-link :to="{name:'Switcher'}">Zurück zur Startseite</router-link>
    </p>
  </div>
</template>

<script>

</script>


<style lang="scss">


</style>
